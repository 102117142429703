<template>
	<div id="landOrderDetail">
		<page-land-rent-order-detail :is-shop="0"></page-land-rent-order-detail>
	</div>
</template>

<script>
	import pageLandRentOrderDetail from '@/components/layout/land/page-land-rent-order-detail.vue'
	export default{
		components:{
			pageLandRentOrderDetail
		},
		beforeRouteLeave (to, from, next) {
			to.meta.keepAlive = to.path == '/land/orderList' ? true :false
			next();
		},
	}
</script>

<style>
</style>
